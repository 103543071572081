.dashboard {
  .custom-grab {
    width: 19.9%;
    cursor: pointer;
    min-width: 170px;
  }
}

.notification-card {
  min-width: 300px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-left: 4px solid red; /* Accent color for the notification */
  border-radius: 4px;
  padding: 0.75rem;
  margin: 0.75rem 0;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08);
}